iframe{
  display: none !important;
}
.skiptranslate{
  display: none !important;
}
body{
  top: 0px !important;
}
.cursor-pointer{
  cursor: pointer;
}