@import url("https://fonts.googleapis.com/css2?family=Varela+Round&display=swap");


nav.mobile-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 24px;
  max-width: 500px;
  margin: 0 auto;
  left: 0;
  right: 0;
  z-index: 999999;
}

.nav-box {
  display: flex;
  padding: 8px;
  background-color: #fff;
  box-shadow: 0px 0px 16px 0px #4444;
  border-radius: 8px;
}
.nav-container {
  display: flex;
  width: 100%;
  list-style: none;
  justify-content: space-around;
}
.nav__item {
  display: flex;
  position: relative;
  padding: 2px;
}
.nav__item.active .nav__item-icon {
  margin-top: -26px;
  box-shadow: 0px 0px 16px 0px #4444;
  background-color: #FFB300;
  color: #fff;
  outline: 2px solid;
}
.nav__item.active .nav__item-text {
  transform: scale(1);
  color: #FFB300;
}
.nav__item-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #2f3046;
  text-decoration: none;
  padding: 0;
}
.nav__item.active .nav__item-link {
  padding: initial;
  height: 46px;
}
.nav__item-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.6em;
  background-color: #fff;
  border-radius: 50%;
  height: 46px;
  width: 46px;
  transition: margin-top 250ms ease-in-out, box-shadow 250ms ease-in-out;
}
.nav__item-text {
  position: absolute;
  bottom: 0;
  transform: scale(0);
  transition: transform 250ms ease-in-out;
}